import React, { useEffect, useState, type FC } from 'react';
import { capitalizeWordsWithUnderscore } from '../../utility/utility';

import { XMarkIcon } from '@heroicons/react/24/outline';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';

import useGetSavedSearchCriteria from '../../hooks/api/SearchCriteria/useGetSavedSearchCriteria';

import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import SizeSettings from './SizeSettings';
import { LockAddressColumn, PaginationPerPage, ShouldShowGridLines, ShouldShowStripedRows } from './TableSettingsMiniComponents';

import SaveSearchCriteria from './SaveSearchCriteria';
import LoadSearchCriteria from './LoadSearchCriteria';
import DeleteSearchCriteria from './DeleteSearchCriteria';
import { useFilteringContext } from '../../contexts/Filtering';

import RowSelectionToggle from './Components/selectors/RowSelectionToggle';
import ColumnsToggle from './Components/selectors/ColumnsToggle';
import FilterDisplay from './Components/filters/FilterDisplay';
import { ChevronRightIcon } from '@heroicons/react/20/solid';

/**
 * Lets make it so when the search criteria is updated, the request is made to see how many properties satisfy the query
 * but when the "apply" button is clicked, it actually fetches those properties?
 */
const tabs = [
  { name: 'Search Criteria', current: true },
  { name: 'Saved Criteria', current: false },
  { name: 'Table Settings', current: false },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export type SearchCriteriaProps = {
  tabName: string;
  drawerToggle: { right: boolean };
  toggleDrawer: (ancdivhor: 'right', open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => void;
}

const keysToSkip = [ 'title', 'id', 'user_id', 'created_at', 'updated_at' ];

const SearchCriteria: FC<SearchCriteriaProps> = ({ tabName = 'Set Search Criteria', drawerToggle, toggleDrawer }) => {
  const [ activeTab, setActiveTab ] = useState(tabs.find((tab) => tab.current).name);
  const [ tabState, setTabState ] = useState(tabs);

  const { dispatch, isFilteringActive } = useFilteringContext();

  useEffect(() => {
    setActiveTab(tabName);
    const updatedTabs = tabState.map((tab) => ({
      ...tab,
      current: tab.name === tabName,
    }));
    setTabState(updatedTabs);
  }, [ tabName ]);

  const {
    totalPropertiesCount,
    criteriaMatchedPropertiesCount,
  } = usePropertyViewerContext();
  const { savedSearchCriteria, errorSavedSearchCriteria, isLoadingSavedSearchCriteria } = useGetSavedSearchCriteria();

  const handleTabClick = (tabName) => {
    const updatedTabs = tabState.map((tab) => ({
      ...tab,
      current: tab.name === tabName,
    }));
    setTabState(updatedTabs);
    setActiveTab(tabName);
  };

  // Clear search criteria from sliders and url params
  const handleClearSearchCriteria = () => {
    const newURL = window.location.pathname;
    window.history.pushState({}, '', newURL);
    // Reset all the dual input elements
    dispatch({ type: 'RESET_FILTERS' });
  };

  function getSearchCriteriaList (criteria) {
    const dataArray = [];

    for (const [ key, value ] of Object.entries(criteria)) {
      if (value === null || value === undefined || value === '' ||
        (Array.isArray(value) && value.length === 0)) {
        continue;
      }
      if (keysToSkip.includes(key)) {
        continue;
      }
      else {
        dataArray.push(`${capitalizeWordsWithUnderscore(key)}: ${value}`);
      }
    }
    return dataArray.map((c) => (<p className="text-gray-600" key={c}>{c}</p>));
  }

  const SearchCriteriaHeader = (
    <div className="bg-white p-1 align-center text-sm">
      <div className='float-left ml-300'>
        <Button onClick={toggleDrawer('right', false)}>
          <ChevronRightIcon className="h-10 w-10 flex-none text-blue-600" aria-hidden="true" />
        </Button>
      </div>
      <div className='flex justify-around h-full py-2 text-gray-400'>
        <div>Total Properties in List: {totalPropertiesCount}</div>
        <div>Filtered Properties count: {criteriaMatchedPropertiesCount}</div>
      </div>
    </div>
  );

  return (
    <div>
      <React.Fragment key='right'>
        <Drawer
          id="search-criteria-drawer"
          variant="persistent"
          anchor='right'
          open={drawerToggle['right']}
          onClose={toggleDrawer('right', false)}
        >
          { SearchCriteriaHeader }
          <div>
            <div className="hidden sm:block">
              <nav className="isolate flex divide-x divide-gray-200 rounded-lg shadow" aria-label="Tabs">
                {tabState.map((tab, tabIdx) => (
                  <a
                    key={tab.name}
                    onClick={() => handleTabClick(tab.name)}
                    className={classNames(
                      tab.current ? 'text-gray-900' : 'text-gray-500 hover:text-gray-700',
                      tabIdx === 0 ? 'rounded-l-lg' : '',
                      tabIdx === tabState.length - 1 ? 'rounded-r-lg' : '',
                      'group relative min-w-0 flex-1 overflow-hidden bg-white py-4 px-4 text-center text-sm font-medium hover:bg-gray-50 focus:z-10'
                    )}
                    aria-current={tab.current ? 'page' : undefined}
                  >
                    <span>{tab.name}</span>
                    <span
                      aria-hidden="true"
                      className={classNames(
                        tab.current ? 'bg-indigo-500' : 'bg-transparent', 'absolute inset-x-0 bottom-0 h-0.5'
                      )}
                    />
                  </a>
                ))}
              </nav>
            </div>
          </div>

          {/* These are our slect dropdown input values */}
          {activeTab === tabState.at(0).name &&
          <div>
            <FilterDisplay />
          </div>
          }

          {/* These are the Saved Search Criteria */}
          {activeTab === tabState.at(1).name &&
          <div className="mx-auto grid max-w-3xl grid-cols-2 px-6 py-10 lg:px-8 xl:gap-x-8 cursor-pointer">
            {savedSearchCriteria?.map((criteria, index) => (
              <div
                key={criteria.id}
                className="rounded-lg p-4 text-sm hover:bg-gray-50 shadow-md"
              >
                <div className="mt-6 block font-semibold text-gray-900">
                  {`Criteria #${index + 1}`}: {criteria.title}
                </div>
                { getSearchCriteriaList(criteria) }
                <LoadSearchCriteria
                  criteriaName={criteria.title}
                  criteria={criteria}
                />
                <DeleteSearchCriteria
                  criteriaTitle={criteria.title}
                  criteriaId={criteria.id}
                />
              </div>
            ))}
          </div>
          }

          {/* These are the Table Settings */}
          {activeTab === tabState.at(2).name &&
          <div className="mx-auto grid max-w-3xl grid-cols-2 px-6 py-10 lg:px-8 xl:gap-x-8 cursor-pointer">
            <SizeSettings />
            <ShouldShowGridLines />
            <ShouldShowStripedRows />
            <PaginationPerPage />
            <RowSelectionToggle />
            {/* <LockAddressColumn /> */}
            {/* ColumnsToggle takes up the whole row */}
            <div className="col-span-2">
              <ColumnsToggle />
            </div>
          </div>
          }

          {/* Calls to action: Save Search Criteria & Clear Search Criteria */}
          <div className="bg-gray-50">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              {/* { activeTab === tabState.at(0).name && isFilteringActive ?  <SaveSearchCriteria /> } */}
              { isFilteringActive ? (
                <div className="grid grid-cols-2 divide-x divide-gray-900/5 border-x border-gray-900/5">
                  <SaveSearchCriteria />
                  <button
                    key="customize_view"
                    className="flex items-center justify-center gap-x-2.5 p-3 text-sm font-semibold leading-6 text-gray-900 hover:bg-gray-100"
                    onClick={handleClearSearchCriteria}
                  >
                    <XMarkIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
                  Clear Search Criteria
                  </button>
                </div>)
                : (<div className="border-x border-gray-900/5">No Filters Set</div>)
              }

            </div>
          </div>
        </Drawer>
      </React.Fragment>
    </div>
  );
};

export default SearchCriteria;
