import { useQuery } from '@tanstack/react-query';
import { getPropertyAgents } from '../../../api/Property/getPropertyAgents';

export default function useGetPropertyAgents(propertyId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getPropertyAgents', propertyId ],
    queryFn: () => getPropertyAgents(propertyId),
    enabled: !!propertyId
  });

  if (getQuery.isError) {
    console.error('There was an error in getPropertyAgents ', getQuery.error.message);
  }

  return {
    listingAgent: getQuery.data?.data.listing_agent ?? null,
    buyingAgent: getQuery.data?.data.buying_agent ?? null,
    errorListUploadsProperties: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    refetch: getQuery.refetch
  };
}
