
import React from 'react';
import { useFilteringContext } from '../../contexts/Filtering';
import { Button } from 'primereact/button';

import FullAddressSearch from '../propertySearch/Components/FullAddressSearch';
import { usePropertyViewerContext } from '../../contexts/PropertyViewer';

const Header = ({ }) => {
  const { dispatch, state } = useFilteringContext();
  const { noFiltersSet, defaultFiltersSet } = state;
  const {
    totalPropertiesCount,
    criteriaMatchedPropertiesCount,
  } = usePropertyViewerContext();

  return (
    <div id="property-list-viewer-header" className="flex justify-between items-center h-18">
      <FullAddressSearch />
      <div className='flex flex-row'>
        <div className='flex justify-between flex-col m-2 text-sm text-black-400'>
          <div>Total Properties in List: {totalPropertiesCount}</div>
          <div>Filtered Properties count: {criteriaMatchedPropertiesCount}</div>
        </div>
        <div className='flex flex-col'>
          <Button
            type="button"
            id="clear-filters-button"
            disabled={noFiltersSet}
            label="Clear Filters"
            onClick={() => dispatch({ type: 'RESET_FILTERS' })}
            className={`rounded text-xs font-medium text-white shadow-sm !p-[6px] ${
              noFiltersSet
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-70 border-transparent'
                : 'hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            }`}
          />
          <Button
            type="button"
            id="reset-to-default-filters-button"
            disabled={defaultFiltersSet}
            label="Default Filters"
            onClick={() => dispatch({ type: 'DEFAULT_FILTERS' })}
            className={`rounded text-xs font-medium text-white shadow-sm !p-[6px] mt-1 ${
              defaultFiltersSet
                ? 'bg-gray-300 text-gray-500 cursor-not-allowed opacity-70 border-transparent'
                : 'hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600'
            }`}
          />
        </div>
      </div>
    </div>
  );
};

export default Header;
