import React, { useEffect, useState, type FC, useCallback } from 'react';
import { GeoFeatureAddressFavorite, PropertiesMapProps, PropertyDataProps } from '../../utility/types';
import Map, { Layer, Popup, Source } from 'react-map-gl';
import type { GeoJSONSource, LayerProps, MapRef, Point, PointLike } from 'react-map-gl';
import mapboxgl from 'mapbox-gl';
import PropertyQuickViewModal from '../common/modals/PropertyQuickViewModal';
import '../../../assets/stylesheets/mapbox.css';
import DrawControl from './draw-control';
import * as turf from '@turf/turf';
import useGetProperties from '../../hooks/api/Property/useGetProperties';
import { getMapboxBoundingBox } from '../../utility/utility';
import { useFilteringContext } from '../../contexts/Filtering';
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import FreehandPolygonMode from './FreehandPolygon';
import MapboxDraw from '@mapbox/mapbox-gl-draw';

const PropertiesMap: FC<PropertiesMapProps> = ({ listSortId }) => {
  const popupWidth = 320;
  const popupWidthPixels = `${popupWidth}px`;
  const streetView = 'mapbox://styles/samreix/cm2lwl6ma000901nt9kk8eo7f';
  const satelliteView = 'mapbox://styles/samreix/cm2lx6csv005x01p97hov6ul4';
  const sourceId = 'properties';
  const favorites = useSelector<RootState>(state => state.favorite.favorites);
  const mapKey = useSelector((state: RootState) => state.map.key);

  const mapRef = useCallback((node: MapRef) => {
    if (node !== null) {
      setMapNode(node);
      setMapBoundingBox(getMapboxBoundingBox(node));
    }
  }, []);

  const { ransackObj, setLassoPolygons } = useFilteringContext();

  const { propertiesData,
    loadingProperties,
    pendingProperties,
    errorProperties } = useGetProperties(listSortId, ransackObj, 500, 1, null);

  const initialProperties: PropertyDataProps[] = propertiesData;
  let latitudes = [];
  let longitudes = [];
  let bounds;

  if (propertiesData) {
    latitudes = propertiesData
      .map(property => property?.address?.latitude ? Number(property.address.latitude) : null)
      .filter(latitude => latitude !== null);

    longitudes = propertiesData
      .map(property => property?.address?.longitude ? Number(property.address.longitude) : null)
      .filter(longitude => longitude !== null);
  }

  if (latitudes.length > 0 && longitudes.length > 0) {
    const sw = new mapboxgl.LngLat(Math.min(...longitudes), Math.min(...latitudes));
    const ne = new mapboxgl.LngLat(Math.max(...longitudes), Math.max(...latitudes));
    bounds = new mapboxgl.LngLatBounds(sw, ne);
  } else {
    // Provide default coordinates if no valid latitudes and longitudes are found
    const defaultLatitude = 0;
    const defaultLongitude = 0;
    const sw = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    const ne = new mapboxgl.LngLat(defaultLongitude, defaultLatitude);
    bounds = new mapboxgl.LngLatBounds(sw, ne);
  }

  const [ filteredProperties, setFilteredProperties ] = useState(initialProperties);
  const [ mapBoundingBox, setMapBoundingBox ] = useState(null);
  const [ popupInfo, setPopupInfo ] = useState(null);
  const [ popupOrientation, setPopupOrientation ] = useState(null);
  const [ popupOffset, setPopupOffset ] = useState<PointLike>([ 0, 0 ]);
  const [ lassoSelections, setLassoSelections ] = useState({});
  const [ mapNode, setMapNode ] = useState(null);
  const [ isSatelliteView, setIsSatelliteView ] = useState(false);
  const [ geoJson, setGeoJson ] = useState(null);

  useEffect(() => {
    handleBoundsChange();
  }, [ initialProperties ]);

  const clusterLayer: LayerProps = {
    id: 'clusters',
    type: 'circle',
    filter: [ 'has', 'point_count' ],
    paint: {
      'circle-color': [ 'step', [ 'get', 'point_count' ], '#51bbd6', 100, '#f1f075', 750, '#f28cb1' ],
      'circle-radius': [ 'step', [ 'get', 'point_count' ], 20, 100, 30, 750, 40 ]
    }
  };

  const clusterCountLayer: LayerProps = {
    id: 'cluster-count',
    type: 'symbol',
    filter: [ 'has', 'point_count' ],
    layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': [ 'DIN Offc Pro Medium', 'Arial Unicode MS Bold' ],
      'text-size': 12
    }
  };

  const unclusteredPointLayer: LayerProps = {
    id: 'unclustered-point',
    type: 'circle',
    filter: [
      'all',
      [ '!', [ 'has', 'point_count' ] ],
      [ '!', [ 'get', 'favorite' ] ],
    ],
    paint: {
      'circle-color': '#4F46E5',
      'circle-radius': 6,
      'circle-stroke-color': '#FFF',
      'circle-stroke-width': 1
    }
  };

  const unclusteredFavoritePointLayer: LayerProps = {
    id: 'unclustered-favorite-point',
    type: 'symbol',
    filter: [
      'all',
      [ '!', [ 'has', 'point_count' ] ],
      [ 'get', 'favorite' ],
    ],
    layout: {
      // mapIconHeart is an image uploaded to our custom mapbox style
      'icon-image': 'mapIconHeart',
      'icon-size': 1,
    }
  };

  // Process properties into GeoJSON format
  useEffect(() => {
    if (!filteredProperties) return;

    const features = filteredProperties.map(property => {
      return {
        'type': 'Feature',
        'geometry': {
          'type': 'Point',
          'coordinates': [ +property.address.longitude, +property.address.latitude ]
        },
        'properties': {
          'address': property.address,
          'favorite': !!favorites[property.id]
        }
      } as GeoFeatureAddressFavorite;
    });

    const json = {
      'type': 'FeatureCollection',
      'features': features
    };

    setGeoJson(json);
  }, [ filteredProperties, favorites ]);

  useEffect(() => {
    calculateFilteredProperties();

    const lassoPolygons = [];
    for (const selection in lassoSelections) {
      lassoPolygons.push(lassoSelections[selection].geometry);
    }
    setLassoPolygons(lassoPolygons);
  }, [ lassoSelections ]);

  const calculateFilteredProperties = () => {
    if (!mapNode || !propertiesData || !propertiesData.length) {
      return;
    }
    let properties;
    if (Object.keys(lassoSelections).length !== 0) {
      properties = calculatePropertiesInLasso();
    }
    else {
      properties = calculatePropertiesInView(initialProperties);
    }
    setFilteredProperties(properties);
  };

  const toggleSatelliteView = () => {
    const newStyle = isSatelliteView ? streetView : satelliteView;
    mapNode.getMap().setStyle(newStyle);
    setIsSatelliteView(!isSatelliteView);
  };

  const calculatePropertiesInLasso = () => {
    if (!propertiesData || !propertiesData.length) {
      return;
    }
    if (Object.keys(lassoSelections).length === 0) {
      return calculatePropertiesInView(initialProperties);
    }
    else {

      const lassoCoordinates = [];
      for (const selection in lassoSelections) {
        lassoCoordinates.push(lassoSelections[selection].geometry.coordinates);
      }
      const multiPoly = turf.multiPolygon(lassoCoordinates);

      const propertiesInLasso = initialProperties.filter(property => {
        const point = turf.point([ Number(property.address.longitude), Number(property.address.latitude) ]);
        return turf.booleanPointInPolygon(point, multiPoly);
      });

      return propertiesInLasso;
    }
  };

  const calculatePropertiesInView = (initialProperties) => {
    if (!mapNode || !initialProperties || !initialProperties.length) {
      return [];
    }
    const bounds = mapNode.getMap().getBounds();
    const ne = bounds.getNorthEast();
    const sw = bounds.getSouthWest();

    const topLeft = { lat: ne.lat, lng: sw.lng };
    const bottomRight = { lat: sw.lat, lng: ne.lng };

    const propertiesInView = initialProperties.filter((property) => {
      const propertyLat = Number(property?.address?.latitude ?? 0);
      const propertyLng = Number(property?.address?.longitude ?? 0);

      if (propertyLat === 0 || propertyLng === 0) {
        console.error('Property has invalid information: ', property);
      }

      return (
        propertyLat <= topLeft.lat &&
        propertyLat >= bottomRight.lat &&
        propertyLng >= topLeft.lng &&
        propertyLng <= bottomRight.lng
      );
    });
    return propertiesInView;
  };

  const handleBoundsChange = () => {
    if (!mapNode || !propertiesData || !propertiesData.length) {
      return;
    }

    calculateFilteredProperties();
  };

  const setPopupOrientationAndOffset = (point: Point) => {

    let orientation;
    let widthOffest;
    let heightOffset;

    if ((mapBoundingBox.bottom - mapBoundingBox.top) /2 > point.y) {
      orientation = 'top';
      heightOffset = 10;
    }
    else {
      orientation = 'bottom';
      heightOffset = -10;
    }

    // Get the horizontal offset to keep at least 5px between edge of popup and edge of map
    const halfOfWidthPlusPadding = popupWidth / 2 + 5;
    const leftOffset = halfOfWidthPlusPadding - point.x;
    const rightOffset = mapBoundingBox.right - halfOfWidthPlusPadding - point.x - mapBoundingBox.left;

    if (leftOffset > 0) {
      widthOffest = leftOffset;
    }
    else if (rightOffset < 0) {
      widthOffest = rightOffset;
    }
    else {
      widthOffest = 0;
    }

    const offset: PointLike = [ widthOffest, heightOffset ];

    setPopupOffset(offset);
    setPopupOrientation(orientation);
  };

  const handleMapClick = (e) => {

    e.originalEvent.stopPropagation();

    const features = e.features || [];
    if (features.length > 0) {
      const feature = features[0];

      // Cluster markers have a cluster_id, individual property markers do not
      // Handle cluster markers
      if (feature.properties.cluster_id) {
        const mapboxSource = mapNode.getMap().getSource(sourceId) as GeoJSONSource;

        mapboxSource.getClusterExpansionZoom(feature.properties.cluster_id, (err, zoom) => {
          if (err) {
            return;
          }

          mapNode.getMap().easeTo({
            center: feature.geometry.coordinates,
            zoom,
            duration: 500
          });
        });
      }
      // Handle individual markers
      else {
        const address = JSON.parse(feature.properties.address);
        setPopupOrientationAndOffset(e.point);

        // Timeout prevents popupInfo being null upon clicking another marker when
        // the popup is open because closing the popup sets the popupInfo to null
        setTimeout(() => setPopupInfo(address), 0);
      }
    }
  };

  const onUpdate = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        newFeatures[f.id] = f;
      }
      return newFeatures;
    });
  }, []);

  const onDelete = useCallback(e => {
    setLassoSelections(currFeatures => {
      const newFeatures = { ...currFeatures };
      for (const f of e.features) {
        delete newFeatures[f.id];
      }
      return newFeatures;
    });
  }, []);

  if (errorProperties) {
    return <div id='PropertiesViewApp-error'>Error: {errorProperties.message}</div>;
  }

  if (pendingProperties || loadingProperties) { // make sure this is correct
    return <div id='PropertiesViewApp-loading'>Loading...</div>;
  }
  if (!propertiesData || !propertiesData.length) {
    return <div>No propertiesData</div>;
  }
  return (
    <>
      <div className="h-[800px] w-full relative">
        <div className="absolute z-10 top-2 right-2 rounded-sm bg-neutral-50 text-indigo-600 border-indigo-600 border-2 hover:text-indigo-800 hover:border-indigo-800">
          <button className="py-0.5 px-1" onClick={toggleSatelliteView}>
            {isSatelliteView ? 'Show Standard View' : 'Show Satellite View'}
          </button>
        </div>
        <Map
          ref={mapRef}
          mapboxAccessToken={mapKey}
          initialViewState={{
            bounds: bounds,
            fitBoundsOptions: { padding: 20 }
          }}
          mapStyle={streetView}
          onZoomEnd={handleBoundsChange}
          onDragEnd={handleBoundsChange}
          onRotate={handleBoundsChange}
          onLoad={handleBoundsChange}
          onClick={handleMapClick}
          onMouseEnter={() => mapNode.getMap().getCanvas().style.cursor = 'pointer'}
          onMouseLeave={() => mapNode.getMap().getCanvas().style.cursor = ''}
          interactiveLayerIds={[ clusterLayer.id, unclusteredPointLayer.id, unclusteredFavoritePointLayer.id ]}
        >
          <Source
            id={sourceId}
            type='geojson'
            data={geoJson}
            cluster={true}
            clusterMaxZoom={12}
          >
            <Layer {...clusterLayer} />
            <Layer {...clusterCountLayer} />
            <Layer {...unclusteredFavoritePointLayer} />
            <Layer {...unclusteredPointLayer} />

          </Source>
          {popupInfo && (
            <Popup
              longitude={popupInfo.longitude}
              latitude={popupInfo.latitude}
              maxWidth={popupWidthPixels}
              anchor={popupOrientation}
              offset={popupOffset}
              closeButton={false}
              closeOnMove={true}
              onClose={() => setPopupInfo(null)}
            >
              <PropertyQuickViewModal
                propertyId={popupInfo.property_id}
              />
            </Popup>
          )}
          <DrawControl
            position='top-left'
            displayControlsDefault={false}
            modes={Object.assign(MapboxDraw.modes, {
              draw_polygon: FreehandPolygonMode
            })}
            controls={{
              polygon: true,
              trash: true
            }}
            onCreate={onUpdate}
            onUpdate={onUpdate}
            onDelete={onDelete}
          />
        </Map>
      </div>
    </>
  );
};
export default PropertiesMap;
