import React, { useEffect, type FC, useState, useCallback } from 'react';
import { ListSortProps } from '../../utility/types';
import FinalDecisionMakingTable from './FinalDecisionMakingTable';
import useGetFinalDecisionMakingProperties from '../../hooks/api/FinalDecisionMaking/useGetFinalDecisionMakingProperties';
import { LoadingSpinner } from '../icons/OurIcons';
import { formatPropertiesForFDMTable } from '../../utility/utility_functions';

export type FinalDecisionMakingContainerProps = {
  activeListSort: ListSortProps;
}

const FinalDecisionMakingContainer: FC<FinalDecisionMakingContainerProps> = ({
  activeListSort,
}) => {
  const { finalDecisionMakingProperties, propertyAuctionNumbers, isLoading, errorFinalDecisionMakingProperties } = useGetFinalDecisionMakingProperties(activeListSort?.id);

  return (
    <div className='w-screen max-h-full top-0 pb-10 text-xs'>
      <div className='m-1 px-2 pl-3 text-xl align-left'>
        <h1>
          {activeListSort ? (
            <>
              <span className="text-gray-700">List: </span>
              <span className='pl-2 font-bold uppercase underline'>{activeListSort.title}</span>
            </>
          ) : (
            <span>Please select a list sort to view the properties ready for Final Decision</span>
          )}
        </h1>
      </div>
      {isLoading && (
        <div className='flex flex-col justify-center items-center align-center'>
          <LoadingSpinner />
          <p>Loading...</p>
        </div>
      )}
      {errorFinalDecisionMakingProperties && (
        <div className='flex justify-center items-center'>
          <p>Error loading properties</p>
        </div>
      )}
      {activeListSort && !isLoading && !errorFinalDecisionMakingProperties && (
        <div className='m-1 px-2 text-xl align-left'>
          <FinalDecisionMakingTable
            properties={formatPropertiesForFDMTable(finalDecisionMakingProperties, propertyAuctionNumbers)}
            activeListSortId={activeListSort.id}
          />
        </div>
      )}
    </div>
  );
};

export default FinalDecisionMakingContainer;
