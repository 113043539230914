import React, { FC, Dispatch, SetStateAction, useState, useEffect } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import PropertyCommentsForm from './PropertyCommentsForm';
import PropertyCommentsView from './PropertyCommentsView';
import useGetPropertyComments from '../../../../hooks/api/Property/useGetPropertyComments';

type PropertyCommentsModalProps = {
  isOpen: boolean;
  propertyId: string;
  setIsPropertyCommentsModalOpen: Dispatch<SetStateAction<boolean>>;
}

const PropertyCommentsModal: FC<PropertyCommentsModalProps> = ({ isOpen, setIsPropertyCommentsModalOpen, propertyId }) => {
  const [ isEdit, setIsEdit ] = useState(false);
  const [ existingCommentId, setExistingCommentId ] = useState('');
  const [ formData, setFormData ] = useState({ title: '', notes: '', photos: [] });

  const { commentData, refetchCommentData } = useGetPropertyComments(propertyId);

  useEffect(() => {
    if (commentData?.comment?.id) {
      setExistingCommentId(commentData?.comment?.id);
      
      const { title, notes, photos } = commentData.comment;
      setFormData({ title: title || '', notes: notes || '', photos: photos || [] });
    }
  }, [ commentData ]);

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50 w-full" onClose={setIsPropertyCommentsModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all px-10 pt-10 w-full overflow-y-scroll mx-5 lg:mx-96 max-h-contain">
                <div className='card justify-center'>
                  {isEdit ? 
                    <PropertyCommentsForm
                      propertyId={propertyId}
                      setIsPropertyCommentsModalOpen={setIsPropertyCommentsModalOpen}
                      setIsEdit={setIsEdit}
                      isEdit={isEdit}
                      commentData={commentData}
                      formData={formData}
                      existingCommentId={existingCommentId}
                      setFormData={setFormData}
                      refetchCommentData={refetchCommentData}
                    />
                    :
                    <PropertyCommentsView
                      propertyId={propertyId}
                      setIsPropertyCommentsModalOpen={setIsPropertyCommentsModalOpen}
                      setIsEdit={setIsEdit}
                      commentData={commentData}
                      formData={formData}
                      refetchCommentData={refetchCommentData}
                    />
                  }
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default PropertyCommentsModal;
