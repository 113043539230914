import React, { useEffect, useRef, useState } from 'react';
import { XMarkIcon } from '@heroicons/react/20/solid';
import { formatAddressFromPieces } from '../../utility/utility';
import baseURL from '../../data/environmentVariables';
import { searchAddresses } from '../../api/Addresses/searchAddresses';

export default function GlobalPropertySearch() {
  const [ search, setSearch ] = useState('');
  const [ searchQuery, setSearchQuery ] = useState('');
  const [ showResults, setShowResults ] = useState(false);
  const [ properties, setProperties ] = useState([]);
  const [ loading, setLoading ] = useState(false);
  const [ error, setError ] = useState(null);
  const dropdownRef = useRef(null);

  // Event listener to detect clicks outside the dropdown to close it
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowResults(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const fetchProperties = async () => {
      setLoading(true);
      setError(null);
      setProperties([]);
      if (Object.keys(searchQuery).length === 0) {
        setLoading(false);
        return;
      }
      try {
        const response = await searchAddresses(searchQuery);
        const googleAddresses = response?.data?.results?.predictions;
        const addresses = googleAddresses?.map((address) => {
          return ({ address: address.description, id: address.place_id });
        });
        setProperties(addresses);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchProperties();
  }, [ searchQuery ]); // Dependencies to trigger re-fetch

  useEffect(() => {
    if (!search) {
      setProperties([]);
      return;
    }
    const timeoutId = setTimeout(() => {
      setSearchQuery(search);
    }, 500); // Debounce time is 300ms

    return () => {
      clearTimeout(timeoutId);
    };
  }, [ search ]);

  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const handleSearchSelection = (id: string) => {
    if (id) {
      const url = `/properties/find-or-generate?place_id=${id}`;
      window.open(url, '_blank');
    }
  };

  const dropdownContent = ()=> {
    if (!search) {
      return null;
    }
    if (loading) return <div>Loading...</div>;
    if (error) return <div>Error: {error}</div>;
    if (showResults) {
      if (properties?.length === 0) {
        return <div>No results found</div>;
      }
      return (
        <ul id="dropdown-content" className="max-h-48 overflow-y-auto py-2 text-sm text-gray-700">
          {properties?.map(({ address, id }) => {
            return (
              <li key={id}
                onClick={()=>handleSearchSelection(id)}
              >
                <div className="flex items-center ps-2 rounded hover:bg-gray-100">
                  <div className="w-full py-2 ms-2 text-sm font-medium text-gray-900 rounded"
                  >{address}</div>
                </div>
              </li>
            );
          })}
        </ul>
      );
    }
  };

  return (
    <div ref={dropdownRef} id="dropdown-container" className="relative z-50 w-80">
      <label htmlFor="input-group-search" className="sr-only">Search</label>
      <div id='label-and-input-container' className="relative">
        <div className="absolute inset-y-0 rtl:inset-r-0 start-0 flex items-center ps-3 pointer-events-none">
          <svg className="w-4 h-4 text-gray-500" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 20 20">
            <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"/>
          </svg>
        </div>
        <input
          type="text"
          id="input-group-search"
          className="block w-full p-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-indigo-500 focus:border-indigo-500"
          placeholder="Search all properties"
          onFocus={() => setShowResults(true)}
          onChange={handleSearchChange}
          value={search}
        />
        <div className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer">
          <XMarkIcon className="w-4 h-4 text-gray-500" onClick={() => setSearch('')} />
        </div>
      </div>
      <div id="dropdown-container" className="absolute mt-1 w-full bg-white rounded-md shadow-lg z-50">
        {dropdownContent()}
      </div>
    </div>
  );
}
