import { useQuery } from '@tanstack/react-query';
import { getPropertiesForListSort } from '../../../api/Property/getPropertiesForListSort';
import { getProperties } from '../../../api/Property/getProperties';
import { PropertyDataProps } from '../../../utility/types';

export type UseGetPropertiesProp = {
  listSortId: string;
  ransackObj: any;
  rows?: number;
  page?: number;
}
// NOT DRY
export default function useGetProperties(listSortId=null, ransackObj={}, rows = null, page = null, sortMeta = null) {
  let propertiesQuery;
  const queryOptions = {
    enabled: ransackObj['state'] !== 'unset',
  };

  if (listSortId) {
    propertiesQuery = useQuery({
      ...queryOptions,
      queryKey: [ 'getPropertiesForListSort', listSortId, ransackObj, rows, page, sortMeta ],
      queryFn: ({ queryKey }) => getPropertiesForListSort({
        listSortId: queryKey[1],
        ransackObj: queryKey[2],
        rows: queryKey[3],
        page: queryKey[4],
        sortMeta: queryKey[5]
      })
    });
  }
  else {
    propertiesQuery = useQuery({
      ...queryOptions,
      queryKey: [ 'getProperties', ransackObj, rows, page, sortMeta ],
      queryFn: ({ queryKey }) => getProperties({
        ransackObj: queryKey[1],
        rows: queryKey[2],
        page: queryKey[3],
        sortMeta: queryKey[5]
      })
    });
  }

  const propertiesData: PropertyDataProps[] = propertiesQuery?.data?.data?.properties_json;
  const data = propertiesQuery?.data?.data;

  // Todo: Instead read this stuff out of the context and not return the data to the component.
  //  this will help with reusability
  return {
    propertiesData: propertiesData,
    totalPropertyRecordsIds: data?.total_records_filtered_ids,
    totalPropertyRecords: data?.total_property_records,
    totalPropertyRecordsFiltered: data?.total_property_records_filtered,

    propertyCounts: {
      total_property_records: data?.total_property_records,
      total_property_records_filtered: data?.total_property_records_filtered,
      total_wanted_property_joins: data?.total_wanted_property_joins,
      total_unwanted_property_joins: data?.total_unwanted_property_joins,
      total_sorted_property: data?.total_sorted_property,
      total_unsorted_property: data?.total_unsorted_property,
    },
    loadingProperties: propertiesQuery.isLoading,
    pendingProperties: propertiesQuery.isPending,
    errorProperties: propertiesQuery.error
  };
};
