import React, { FC, Fragment, useEffect, useRef, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { CreateListSortFormDataProps, NewListSortData } from '../../utility/types';
import { useCreateListSort } from '../../hooks/api/ListSort/useCreateListSort';
import { useGetListUploads } from '../../hooks/api/ListUpload/useGetListUploads';

interface CreateListSortModalProps {
  isOpen: boolean;
  onClose: () => void;
  mlsDataSources: string[];
}

const CreateListSortModal: FC<CreateListSortModalProps> = ({
  isOpen,
  onClose,
  mlsDataSources
}) => {
  const [ formData, setFormData ] = useState<CreateListSortFormDataProps>({
    title: '',
    notes: '',
    list_upload: '',
  });
  const saveButtonRef = useRef(null);
  const [ isInvalidName, setIsInvalidName ] = useState(false);
  const { data: listUploads, ...getListUploads } = useGetListUploads();
  const createListSortMutation = useCreateListSort();

  const navigateToListSort = (list_sort_id: string) => {
    window.location.href = `/list-sorts/${list_sort_id}`;
  };

  // Use a useEffect to handle the modal closing logic
  useEffect(() => {
    if (createListSortMutation.isSuccess) {
      const listSortId = createListSortMutation?.data?.data?.list_sort?.id;
      if (listSortId) {
        navigateToListSort(listSortId);
      } else {
        console.error(`List sort was not successfully created: ${createListSortMutation?.data}`);
      }
      onClose();
    }
  }, [ createListSortMutation.isSuccess, onClose ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>) => {
    if (e.target.name === 'name') {
      setIsInvalidName(false);
    }
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (!formData.title) {
      setIsInvalidName(true);
      return;
    }
    createListSortMutation.mutateAsync({ list_sort: formData });
  };

  return (
    <>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" initialFocus={saveButtonRef} onClose={onClose}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                  <div>
                    <div className="mt-3 sm:mt-5">
                      <Dialog.Title as="h2" className="text-xl text-center font-semibold mb-4">
                        Create List Sort
                      </Dialog.Title>
                      <div className="bg-white p-4 rounded-md">
                        <form>
                          <div className="mb-4">
                            <label htmlFor="name" className="block text-sm font-medium leading-6 text-gray-900">Name</label>
                            {isInvalidName && <span style={{ color: 'red' }}>Name cannot be empty</span>}
                            <input
                              type="text"
                              id="title"
                              name="title"
                              value={formData.title}
                              onChange={handleInputChange}
                              className="w-full border rounded-md px-3 py-2"
                              required
                            />
                          </div>
                          <div className="mb-4">
                            <label htmlFor="notes" className="block text-sm font-medium leading-6 text-gray-900">Notes (optional)
                            </label>
                            <textarea
                              id="notes"
                              name="notes"
                              value={formData.notes}
                              onChange={handleInputChange}
                              className="w-full border rounded-md px-3 py-2"
                            />
                          </div>
                          <div className="mb-4">
                            <div className="flex items-center justify-between">
                              <label htmlFor="list_upload" className="block text-sm font-medium leading-6 text-gray-900">
                                Select List Data Source
                              </label>
                              <div className="text-sm">
                                <a href={'/list-uploads'} className="font-semibold text-indigo-600 hover:text-indigo-500">
                                  Manage Lists
                                </a>
                              </div>
                            </div>
                            {getListUploads.isPending ? <div> Loading </div> :
                              <select
                                id="list_upload"
                                name="list_upload"
                                value={formData.list_upload}
                                onChange={handleInputChange}
                                className="w-full border rounded-md px-3 py-2"
                              >
                                <option value="">All Properties</option>
                                {mlsDataSources.map((mlsSource) => (
                                  <option key={`list-upload-mls-data-source-${mlsSource}`} value={mlsSource}>
                                    {mlsSource} MLS Properties
                                  </option>
                                ))}
                                <option value="NONE">Empty</option>
                                {listUploads.map((upload) => (
                                  <option key={upload.id} value={upload.id}>
                                    {upload.list_name}
                                  </option>
                                ))}
                              </select>}
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                  <div className="mt-5 sm:mt-6 sm:grid sm:grid-flow-row-dense sm:grid-cols-2 sm:gap-3">
                    <button
                      type="button"
                      className="inline-flex w-full justify-center rounded-md bg-indigo-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:col-start-2"
                      onClick={handleSubmit}
                      ref={saveButtonRef}
                    >
                      Save
                    </button>
                    <button
                      type="button"
                      className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:col-start-1 sm:mt-0"
                      onClick={onClose}
                    >
                      Cancel
                    </button>
                  </div>
                  {createListSortMutation.isError && <div> something went wrong</div>}
                  {createListSortMutation.isPending && <div> Processing new list sort</div>}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default CreateListSortModal;
