import React, { type FC } from 'react';
import { AvmResult, PropertyDataProps } from '../../../utility/types';
import { formatFloat, getPricePerSqFt, isPropertyActive } from '../../../utility/utility_functions';

type PropertyQuickViewModalFooterProps = {
  property: PropertyDataProps;
  avmResult: AvmResult;
  isAvmComp?: Boolean;
};

const PropertyQuickViewModalFooter: FC<PropertyQuickViewModalFooterProps> = ({ property, avmResult, isAvmComp = false }) => {
  if (!property) {
    return;
  }

  const id = property.id;
  const listItemStyling = 'pr-1 border-r last:border-0 dark:text-gray-100';

  const formatAddress = () => {
    return `${property?.address?.street || ''}${property?.address?.street && property?.address?.city ? ', ' : ''}${property?.address?.city || ''}`;
  };

  const getPropertyAddressHtml = () => {
    let addr;
    if (property?.address?.street || property?.address?.city) {
      addr = formatAddress();
    }
    return addr && <div className={'text-xs text-gray-700 dark:text-gray-300'}>{addr}</div>;
  };

  const getPropertyPricingHtml = () => {
    const pricing = [];
    let price = property?.list_price;
    let priceTitle = 'List Price';
    let pricePerSqft = getPricePerSqFt(property);

    if (!isAvmComp && !isPropertyActive(property)) {
      if (avmResult?.estimated_value) {
        price = avmResult.estimated_value;
        priceTitle = 'REIX Estimate';
      }
      if (avmResult?.estimated_dollar_per_sqft) {
        pricePerSqft = avmResult.estimated_dollar_per_sqft;
      }
    }
    else if (isAvmComp && !isPropertyActive(property) && property.last_sold_listing) {
      price = property.last_sold_listing.close_price;
      pricePerSqft = property.last_sold_listing.sold_price_per_sqft;
      priceTitle = 'Sold Price';
    }

    if (price) {
      pricing.push(<li key={id + 'price-title'} className="dark:text-gray-100">{priceTitle}:</li>);
      pricing.push(<li key={id + 'prc'} className={listItemStyling}>${price.toLocaleString()}</li>);
    }

    if (pricePerSqft) {
      pricing.push(<li key={id + 'prcsqft'} className={listItemStyling}>
        ${formatFloat(pricePerSqft, 2).toLocaleString()} <span className="opacity-70">$/sqft</span>
      </li>);
    }

    return pricing.length > 0 && (
      <ul className="flex justify-start text-sm gap-x-1 pb-1">
        {pricing}
      </ul>
    );
  };

  const getPropertySpecificationsHtml = () => {
    const details = [];

    if (property.status) {
      details.push(<li key={id + 'status'} className={listItemStyling}>{property.status}</li>);
    }

    if (property.bedrooms) {
      details.push(<li key={id + 'bds'} className={listItemStyling}>{property.bedrooms} <span className="opacity-70">bds</span></li>);
    }

    if (property.bathrooms) {
      details.push(<li key={id + 'ba'} className={listItemStyling}>{property.bathrooms} <span className="opacity-70">ba</span></li>);
    }

    if (property.sqft) {
      details.push(<li key={id + 'sqft'} className={listItemStyling}>{property.sqft} <span className="opacity-70">sqft</span></li>);
    }

    return details.length > 0 && (
      <ul className="flex justify-start text-sm gap-x-1 pb-1">
        {details}
      </ul>
    );
  };

  return (
    <div className="p-2 bg-white dark:bg-gray-900 shadow rounded-b-lg">
      {getPropertyPricingHtml()}
      {getPropertySpecificationsHtml()}
      {getPropertyAddressHtml()}
    </div>
  );
};
export default PropertyQuickViewModalFooter;
