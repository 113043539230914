import React, { type FC } from 'react';
import { Loader } from '../icons/OurIcons';

import { formatFloat, formatDate, checkIfPropertyIsOnMarket, getPricePerSqFt } from '../../utility/utility_functions';

export type PropertySummaryProps = {
  soldAvmResult: any;
  propertyDetails: any;
}

const PropertyDetailsSummary: FC<PropertySummaryProps> = ({ soldAvmResult, propertyDetails }) => {

  const getDaysOnMarket = (property): string => {
    const onMarketDate = property?.on_market_date;
    if (!onMarketDate) {
      return 'N/A';
    }
    const parsedDate = new Date(onMarketDate);
    const currentDate = new Date();
    const differenceMs = currentDate.getTime() - parsedDate.getTime();
    const dateInDays = Math.floor(differenceMs / (1000 * 60 * 60 * 24));
    return `${dateInDays} days`;
  };

  const getPropertyStatus = (): string => {
    let statusString = propertyDetails?.status ?? 'N/A';
    let saleOrRent = propertyDetails?.sale_or_rent ?? 'N/A';
    // check if property has an active status
    if (checkIfPropertyIsOnMarket(statusString) === 'Yes' && saleOrRent === 'SALE') {
      statusString += ' - For Sale';
    } else if (checkIfPropertyIsOnMarket(statusString) === 'Yes' && saleOrRent === 'RENT') {
      statusString += ' - For Rent';
    }

    return statusString;
  };

  const getPropertyOnMarketItems = () => {
    const items = [];
    const pricePerSqft = getPricePerSqFt(propertyDetails);

    items.push(<p key="list-price"><b>List Price: </b>{propertyDetails?.list_price ? `$${formatFloat(propertyDetails?.list_price, 0)}` : 'N/A'}</p>);
    items.push(<p key="list-price-sqft"><b>List Price $/Sq Ft: </b>{pricePerSqft ? `$${formatFloat(pricePerSqft, 2)}` : 'N/A'}</p>);

    return items;
  };

  const getTaxRate = (): string => {
    if (propertyDetails?.total_tax_amount == null || propertyDetails?.assessed_value == null) return 'N/A';

    const taxRatePercent = (propertyDetails?.total_tax_amount / propertyDetails?.assessed_value) * 100;

    return formatFloat(taxRatePercent, 2) + '%';
  };

  if (!soldAvmResult || !propertyDetails) {
    return (
      <div className='w-screen content-center px-10'>
        <div className='mx-auto mt-10 w-96'>
          <Loader />
        </div>
      </div>
    );
  }

  return (
    <>
      <div className='mb-5'>
        <p><b>Status: </b>{getPropertyStatus()}</p>
        {checkIfPropertyIsOnMarket(propertyDetails?.status) === 'No' ?
          <p><b>Off-Market Date: </b>{formatDate(propertyDetails?.last_sold_listing?.off_market_date)}</p>
          : null
        }
        <p><b>MLS Number: </b>{propertyDetails?.last_mls_id ?? 'No MLS history'}</p>
        {getPropertyStatus() === 'Sold' ?
          <>
            <p><b>Close Date: </b>{formatDate(propertyDetails?.last_sold_listing?.close_date) ?? 'N/A'}</p>
            <p><b>Sold Price: </b>{propertyDetails?.last_sold_listing?.close_price ? `$${formatFloat(propertyDetails?.last_sold_listing?.close_price, 0)}` : 'N/A'}</p>
          </> : null}
      </div>
      <div className="w-full border-t border-gray">
        <div className="grid gap-x-5 grid-cols-2 sm:gap-x-10 w-contain justify-items-center mx-auto text-nowrap whitespace-nowrap pt-5">
          <ul className="list-none text-left">
            {checkIfPropertyIsOnMarket(propertyDetails?.status) === 'Yes' && getPropertyOnMarketItems()}
            <li><b>Value Estimate: </b>{soldAvmResult?.result?.estimated_value ? `$${formatFloat(soldAvmResult?.result?.estimated_value, 0)}` : 'N/A'}</li>
            <li><b>$/Sq Ft Estimate: </b>{soldAvmResult?.result?.estimated_dollar_per_sqft ? `$${formatFloat(soldAvmResult?.result?.estimated_dollar_per_sqft, 2)}` : 'N/A'}</li>
            <li><b>Annual Taxes: </b>{propertyDetails?.total_tax_amount ? `$${formatFloat(propertyDetails?.total_tax_amount, 2)}` : 'N/A'}</li>
            <li><b>Tax Assessed Value: </b>{propertyDetails?.assessed_value ? `$${formatFloat(propertyDetails?.assessed_value, 2)}` : 'N/A'}</li>
            <li><b>Annual Tax Rate: </b>{getTaxRate()}</li>
            <li><b>Days on Market: </b>{getDaysOnMarket(propertyDetails)}</li>
            <li><b>Neighborhood: </b>{propertyDetails?.subdivision}</li>
          </ul>
          <ul className="list-disc text-left">
            <li>{propertyDetails?.property_class ?? 'N/A'} {propertyDetails?.property_class === 'Unknown' ? 'Type' : null}</li>
            <li>{propertyDetails?.bedrooms ?? 'N/A'} Bed</li>
            <li>{propertyDetails?.bathrooms ?? 'N/A'} Bath</li>
            <li>{propertyDetails?.sqft ? formatFloat(propertyDetails?.sqft, 0) : 'N/A'} Sq Ft</li>
            <li>{propertyDetails?.stories ?? 'N/A'} Stories</li>
            <li>Year Build {propertyDetails?.year_built ?? 'N/A'}</li>
            <li>{propertyDetails?.lot_size_acres ?? 'N/A'} Acres</li>
            <li>{propertyDetails?.garage_spaces ?? 'N/A'} Car Garage</li>
            <li>{propertyDetails?.hoa ?? 'N/A'} HOA</li>
          </ul>
        </div>
      </div>
    </>
  );
};

export default PropertyDetailsSummary;
