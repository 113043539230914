import React from 'react';

export type IconProps = {
  color?: string;
  className?: string;
  size?: string | number;
}

export const XCircleIcon_inactive = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="x-circle-inactive"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
    </svg>
  );
};

export const OpenOnNewPage = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="open-on-new-page"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M13.5 6H5.25A2.25 2.25 0 0 0 3 8.25v10.5A2.25 2.25 0 0 0 5.25 21h10.5A2.25 2.25 0 0 0 18 18.75V10.5m-10.5 6L21 3m0 0h-5.25M21 3v5.25" />
    </svg>
  );
};

export const XCircleIcon_active = ({ color = 'DarkRed' }: IconProps) => {
  return (
    <svg
      id="x-circle-active"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      className="w-6 h-6"
    >
      <path fillRule="evenodd" d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.72 6.97a.75.75 0 10-1.06 1.06L10.94 12l-1.72 1.72a.75.75 0 101.06 1.06L12 13.06l1.72 1.72a.75.75 0 101.06-1.06L13.06 12l1.72-1.72a.75.75 0 10-1.06-1.06L12 10.94l-1.72-1.72z" clipRule="evenodd" />
    </svg>
  );
};

export const CheckIcon_inactive = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="check-icon-inactive"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      className="w-6 h-6"
    >
      <path fillRule="evenodd" d="M19.916 4.626a.75.75 0 01.208 1.04l-9 13.5a.75.75 0 01-1.154.114l-6-6a.75.75 0 011.06-1.06l5.353 5.353 8.493-12.739a.75.75 0 011.04-.208z" clipRule="evenodd" />
    </svg>

  );
};

export const CheckIcon_active = ({ color = 'ForestGreen' }: IconProps) => {
  return (
    <svg
      id="check-icon-active"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      className="w-6 h-6"
    >
      <path fillRule="evenodd" d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm13.36-1.814a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z" clipRule="evenodd" />
    </svg>

  );
};

export const FavoriteIcon_inactive = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="favorite-icon-inactive"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z" />
    </svg>

  );
};

export const FavoriteIcon_active = ({ color = 'Red' }: IconProps) => {
  return (
    <svg
      id="favorite-icon-active"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill={color}
      className="w-6 h-6"
    >
      <path d="M11.645 20.91l-.007-.003-.022-.012a15.247 15.247 0 01-.383-.218 25.18 25.18 0 01-4.244-3.17C4.688 15.36 2.25 12.174 2.25 8.25 2.25 5.322 4.714 3 7.688 3A5.5 5.5 0 0112 5.052 5.5 5.5 0 0116.313 3c2.973 0 5.437 2.322 5.437 5.25 0 3.925-2.438 7.111-4.739 9.256a25.175 25.175 0 01-4.244 3.17 15.247 15.247 0 01-.383.219l-.022.012-.007.004-.003.001a.752.752 0 01-.704 0l-.003-.001z" />
    </svg>
  );
};

export const MapIcon_heart = ({ color = 'Red' }: IconProps) => {
  return (
    <svg
      id="map-icon-heart"
      xmlns="http://www.w3.org/2000/svg"
      className="w-6 h-6"
      viewBox="-2 -2 22 22"
    >
      <path fill="white" className="drop-shadow"
        d="M 5.27,0.00
          C 5.27,0.00 5.27,0.00 5.27,0.00 3.82,0.00 2.50,0.65 1.54,1.69 0.59,2.73 -0.00,4.17 -0.00,5.77 -0.00,7.36 0.59,8.80 1.54,9.84 1.54,9.84 5.27,13.92 5.27,13.92 5.27,13.92 9.00,18.00 9.00,18.00 9.00,18.00 12.73,13.92 12.73,13.92 12.73,13.92 16.46,9.84 16.46,9.84 17.41,8.80 18.00,7.36 18.00,5.77 18.00,4.17 17.41,2.73 16.46,1.69 15.50,0.65 14.18,0.00 12.73,0.00 11.27,0.00 9.95,0.65 9.00,1.69 8.05,0.65 6.73,0.00 5.27,0.00 5.27,0.00 5.27,0.00 5.27,0.00 Z" />
      <path fill={color}
        d="M 6.20,2.03
          C 6.20,2.03 6.20,2.03 6.20,2.03 5.11,2.03 4.12,2.51 3.41,3.29 2.69,4.06 2.25,5.13 2.25,6.31 2.25,7.49 2.69,8.56 3.41,9.34 3.41,9.34 6.20,12.36 6.20,12.36 6.20,12.36 9.00,15.38 9.00,15.38 9.00,15.38 11.80,12.36 11.80,12.36 11.80,12.36 14.59,9.34 14.59,9.34 15.31,8.56 15.75,7.49 15.75,6.31 15.75,5.13 15.31,4.06 14.59,3.29 13.88,2.51 12.89,2.03 11.80,2.03 10.70,2.03 9.72,2.51 9.00,3.29 8.28,2.51 7.30,2.03 6.20,2.03 6.20,2.03 6.20,2.03 6.20,2.03 Z">
      </path>
    </svg>
  );
};

export const MinusIcon = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="minus-icon"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M18 12H6" />
    </svg>
  );
};
export const CircleMinusIcon = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="minus-icon"
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke={color}
      className="w-6 h-6"
    >
      <path strokeLinecap="round" strokeLinejoin="round" d="M15 12H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
    </svg>
  );
};

export const IconHouse = ({ color = 'Black' }: IconProps) => {
  return (
    <svg
      id="icon-house"
      viewBox="0 0 16 16"
      height="1em"
      width="1em"
      fill={color}
    >
      <path
        fillRule="evenodd"
        d="M2 13.5V7h1v6.5a.5.5 0 00.5.5h9a.5.5 0 00.5-.5V7h1v6.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5zm11-11V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
      />
      <path
        fillRule="evenodd"
        d="M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z"
      />
    </svg>
  );
};

export const IconHouseFill = ({ color = 'Black', ...rest }: IconProps) => {
  return (
    <svg
      id="icon-house-fill"
      className="fill-blue h-4 w-4"
      viewBox="0 0 16 16"
      fill={color}
      {...rest}
    >
      <path
        fillRule="evenodd"
        d="M8 3.293l6 6V13.5a1.5 1.5 0 01-1.5 1.5h-9A1.5 1.5 0 012 13.5V9.293l6-6zm5-.793V6l-2-2V2.5a.5.5 0 01.5-.5h1a.5.5 0 01.5.5z"
      />
      <path
        fillRule="evenodd"
        d="M7.293 1.5a1 1 0 011.414 0l6.647 6.646a.5.5 0 01-.708.708L8 2.207 1.354 8.854a.5.5 0 11-.708-.708L7.293 1.5z"
      />
    </svg>
  );
};

export const ArrowIcon = ({ className='', color='white' }) => {
  return (
    <svg
      id="arrow-icon"
      className={` stroke-[.5] ${className}`}
      fillOpacity={0}
      strokeLinecap="round"
      viewBox="0 0 4 8"
      stroke={color}
    >
      <path className="drop-shadow-md" d="M 1 1 L 3 4 L 1 7" />
    </svg>
  );
};
export const ArrowIconSizeable = ({ className='', color='black', width='12px', height='24px' }) => {
  return (
    <svg
      id="arrow-icon"
      className={` stroke-[.5] ${className}`}
      fillOpacity={0}
      strokeLinecap="round"
      viewBox="0 0 4 8"
      stroke={color}
      width={width}
      height={height}
    >
      <path className="drop-shadow-md" d="M 1 1 L 3 4 L 1 7" />
    </svg>
  );
};
export const LoadingSpinner = ({ color = 'Black', size = '8' }: IconProps) => {
  return (
    <svg
      id="loading-spinner"
      aria-hidden="true"
      className={`inline w-${size} h-${size} text-gray-200 animate-spin dark:text-gray-600 fill-purple-600`}
      viewBox="0 0 100 101"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="Black" />
      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
    </svg>
  );
};

export const Loader = ({ width = null, height = null }) => {
  if (width && height) {
    return (
      <svg
        id="loader"
        className="stroke-indigo-600 stroke-opacity-90 animate-spin"
        fillOpacity={0}
        viewBox="0 0 10 10"
        width={width}
        height={height}
      >
        <path d="M 5 1 C 3 1 1 3 1 5 M 5 9 C 7 9 9 7 9 5" />
      </svg>
    );
  }
  return (
    <svg className="stroke-indigo-600/90 animate-spin" fillOpacity={0} viewBox="0 0 10 10">
      <path d="M 5 1 C 3 1 1 3 1 5 M 5 9 C 7 9 9 7 9 5" />
    </svg>
  );
};

export const CloseIcon= ({ width = 10, height = 10 }) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 26 26">
      <path style={{ fill: '#030104' }} d="M21.125,0H4.875C2.182,0,0,2.182,0,4.875v16.25C0,23.818,2.182,26,4.875,26h16.25
        C23.818,26,26,23.818,26,21.125V4.875C26,2.182,23.818,0,21.125,0z M18.78,17.394l-1.388,1.387c-0.254,0.255-0.67,0.255-0.924,0
        L13,15.313L9.533,18.78c-0.255,0.255-0.67,0.255-0.925-0.002L7.22,17.394c-0.253-0.256-0.253-0.669,0-0.926l3.468-3.467
        L7.221,9.534c-0.254-0.256-0.254-0.672,0-0.925l1.388-1.388c0.255-0.257,0.671-0.257,0.925,0L13,10.689l3.468-3.468
        c0.255-0.257,0.671-0.257,0.924,0l1.388,1.386c0.254,0.255,0.254,0.671,0.001,0.927l-3.468,3.467l3.468,3.467
        C19.033,16.725,19.033,17.138,18.78,17.394z"/>
    </svg>
  );
};

export const TrashIcon = ({ width = 10, height = 10 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="Black"
      width={width}
      height={height}>
      <path d="m14.74 9-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 0 1-2.244 2.077H8.084a2.25 2.25 0 0 1-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 0 0-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 0 1 3.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 0 0-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 0 0-7.5 0" />
    </svg>
  );
};

export const Unsorted = ({ color = 'Black' }: IconProps) => {
  return (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={color} xmlns="http://www.w3.org/2000/svg" className="p-icon p-sortable-column-icon ml-2 text-slate-700 dark:text-white/70" aria-hidden="true" data-pc-section="sorticon"><path d="M5.64515 3.61291C5.47353 3.61291 5.30192 3.54968 5.16644 3.4142L3.38708 1.63484L1.60773 3.4142C1.34579 3.67613 0.912244 3.67613 0.650309 3.4142C0.388374 3.15226 0.388374 2.71871 0.650309 2.45678L2.90837 0.198712C3.17031 -0.0632236 3.60386 -0.0632236 3.86579 0.198712L6.12386 2.45678C6.38579 2.71871 6.38579 3.15226 6.12386 3.4142C5.98837 3.54968 5.81676 3.61291 5.64515 3.61291Z" fill="Black"></path><path d="M3.38714 14C3.01681 14 2.70972 13.6929 2.70972 13.3226V0.677419C2.70972 0.307097 3.01681 0 3.38714 0C3.75746 0 4.06456 0.307097 4.06456 0.677419V13.3226C4.06456 13.6929 3.75746 14 3.38714 14Z" fill="Black"></path><path d="M10.6129 14C10.4413 14 10.2697 13.9368 10.1342 13.8013L7.87611 11.5432C7.61418 11.2813 7.61418 10.8477 7.87611 10.5858C8.13805 10.3239 8.5716 10.3239 8.83353 10.5858L10.6129 12.3652L12.3922 10.5858C12.6542 10.3239 13.0877 10.3239 13.3497 10.5858C13.6116 10.8477 13.6116 11.2813 13.3497 11.5432L11.0916 13.8013C10.9561 13.9368 10.7845 14 10.6129 14Z" fill="Black"></path><path d="M10.6129 14C10.2426 14 9.93552 13.6929 9.93552 13.3226V0.677419C9.93552 0.307097 10.2426 0 10.6129 0C10.9833 0 11.2904 0.307097 11.2904 0.677419V13.3226C11.2904 13.6929 10.9832 14 10.6129 14Z" fill="Black"></path></svg>
  );
};

export const SortAscending = ({ color = 'Black' }: IconProps) => {
  return  (
    <svg width="14" height="14" viewBox="0 0 14 14" fill={color}  stroke={'#2a89ff'} xmlns="http://www.w3.org/2000/svg" className="p-icon p-sortable-column-icon ml-2 text-blue-700 dark:text-white/80" aria-hidden="true" data-pc-section="sorticon"><path d="M4.59864 3.99958C4.44662 3.99958 4.2946 3.94357 4.17458 3.82356L2.59836 2.24734L1.02214 3.82356C0.79011 4.05559 0.406057 4.05559 0.174024 3.82356C-0.0580081 3.59152 -0.0580081 3.20747 0.174024 2.97544L2.1743 0.97516C2.40634 0.743127 2.79039 0.743127 3.02242 0.97516L5.0227 2.97544C5.25473 3.20747 5.25473 3.59152 5.0227 3.82356C4.90268 3.94357 4.75066 3.99958 4.59864 3.99958Z" fill="Black"></path><path d="M2.59841 13.2009C2.27036 13.2009 1.99833 12.9288 1.99833 12.6008V1.39922C1.99833 1.07117 2.27036 0.799133 2.59841 0.799133C2.92646 0.799133 3.19849 1.07117 3.19849 1.39922V12.6008C3.19849 12.9288 2.92646 13.2009 2.59841 13.2009Z" fill="Black"></path><path d="M13.3999 11.2006H6.99902C6.67098 11.2006 6.39894 10.9285 6.39894 10.6005C6.39894 10.2725 6.67098 10.0004 6.99902 10.0004H13.3999C13.728 10.0004 14 10.2725 14 10.6005C14 10.9285 13.728 11.2006 13.3999 11.2006Z" fill="Black"></path><path d="M10.1995 6.39991H6.99902C6.67098 6.39991 6.39894 6.12788 6.39894 5.79983C6.39894 5.47179 6.67098 5.19975 6.99902 5.19975H10.1995C10.5275 5.19975 10.7996 5.47179 10.7996 5.79983C10.7996 6.12788 10.5275 6.39991 10.1995 6.39991Z" fill="Black"></path><path d="M8.59925 3.99958H6.99902C6.67098 3.99958 6.39894 3.72754 6.39894 3.3995C6.39894 3.07145 6.67098 2.79941 6.99902 2.79941H8.59925C8.92729 2.79941 9.19933 3.07145 9.19933 3.3995C9.19933 3.72754 8.92729 3.99958 8.59925 3.99958Z" fill="Black"></path><path d="M11.7997 8.80025H6.99902C6.67098 8.80025 6.39894 8.52821 6.39894 8.20017C6.39894 7.87212 6.67098 7.60008 6.99902 7.60008H11.7997C12.1277 7.60008 12.3998 7.87212 12.3998 8.20017C12.3998 8.52821 12.1277 8.80025 11.7997 8.80025Z" fill="Black"></path></svg>
  );
};

export const SortDescending = ({ color = 'Black' }: IconProps) => {
  return  (
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" stroke={'#2a89ff'} xmlns="http://www.w3.org/2000/svg" className="p-icon p-sortable-column-icon ml-2 text-blue-700 dark:text-white/80" aria-hidden="true" data-pc-section="sorticon"><path d="M2.59836 13.2009C2.44634 13.2009 2.29432 13.1449 2.1743 13.0248L0.174024 11.0246C-0.0580081 10.7925 -0.0580081 10.4085 0.174024 10.1764C0.406057 9.94441 0.79011 9.94441 1.02214 10.1764L2.59836 11.7527L4.17458 10.1764C4.40662 9.94441 4.79067 9.94441 5.0227 10.1764C5.25473 10.4085 5.25473 10.7925 5.0227 11.0246L3.02242 13.0248C2.90241 13.1449 2.75038 13.2009 2.59836 13.2009Z" fill="Black"></path><path d="M2.59836 13.2009C2.27032 13.2009 1.99833 12.9288 1.99833 12.6008V1.39922C1.99833 1.07117 2.27036 0.799133 2.59841 0.799133C2.92646 0.799133 3.19849 1.07117 3.19849 1.39922V12.6008C3.19849 12.9288 2.92641 13.2009 2.59836 13.2009Z" fill="Black"></path><path d="M13.3999 11.2006H6.99902C6.67098 11.2006 6.39894 10.9285 6.39894 10.6005C6.39894 10.2725 6.67098 10.0004 6.99902 10.0004H13.3999C13.728 10.0004 14 10.2725 14 10.6005C14 10.9285 13.728 11.2006 13.3999 11.2006Z" fill="Black"></path><path d="M10.1995 6.39991H6.99902C6.67098 6.39991 6.39894 6.12788 6.39894 5.79983C6.39894 5.47179 6.67098 5.19975 6.99902 5.19975H10.1995C10.5275 5.19975 10.7996 5.47179 10.7996 5.79983C10.7996 6.12788 10.5275 6.39991 10.1995 6.39991Z" fill="Black"></path><path d="M8.59925 3.99958H6.99902C6.67098 3.99958 6.39894 3.72754 6.39894 3.3995C6.39894 3.07145 6.67098 2.79941 6.99902 2.79941H8.59925C8.92729 2.79941 9.19933 3.07145 9.19933 3.3995C9.19933 3.72754 8.92729 3.99958 8.59925 3.99958Z" fill="Black"></path><path d="M11.7997 8.80025H6.99902C6.67098 8.80025 6.39894 8.52821 6.39894 8.20017C6.39894 7.87212 6.67098 7.60008 6.99902 7.60008H11.7997C12.1277 7.60008 12.3998 7.87212 12.3998 8.20017C12.3998 8.52821 12.1277 8.80025 11.7997 8.80025Z" fill="Black"></path></svg>
  );
};

export const IconX = ({ color = 'Black' }: IconProps) => {
  return (
    <svg  xmlns="http://www.w3.org/2000/svg"  width="24"  height="24"  viewBox="0 0 24 24"  fill={color}  stroke="Black"  strokeWidth="2"  strokeLinecap="round"  strokeLinejoin="round"  className="icon icon-tabler icons-tabler-outline icon-tabler-x"><path stroke="none" d="M0 0h24v24H0z" fill="none"/><path d="M18 6l-12 12" /><path d="M6 6l12 12" /></svg>
  );
};

export const RedCheckMark = ({ width = 10, height = 10 }) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="red" height={height} width={width}>
      <path strokeLinecap="round" strokeLinejoin="round" d="m4.5 12.75 6 6 9-13.5" />
    </svg>
  );
};
