import React, { useState, type FC } from 'react';
import { FinalDecisionMakingProperty } from '../../utility/types';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import '../../../../app/assets/stylesheets/fdm-property-list-styles.css';
import 'primereact/resources/themes/lara-light-indigo/theme.css';   // theme
import 'primeflex/primeflex.css';                                   // css utility
import 'primeicons/primeicons.css';
import 'primereact/resources/primereact.css';
import ConfirmDeleteLSPJModal from './ConfirmDeleteLSPJModal';
import { Button } from 'primereact/button';
import { TrashIcon } from '@heroicons/react/20/solid';

type FinalDecisionMakingTableProps = {
  properties: FinalDecisionMakingProperty[];
  activeListSortId: string;
}

const FinalDecisionMakingTable: FC<FinalDecisionMakingTableProps> = ({ properties, activeListSortId }) => {
  const [ modalVisible, setModalVisible ] = useState(false);
  const [ selectedPropertyId, setSelectedPropertyId ] = useState(null);

  const handleDeleteClick = (propertyId: string) => {
    setSelectedPropertyId(propertyId);
    setModalVisible(true);
  };

  const handleDeleteConfirm = () => {
    properties.splice(properties.findIndex((property) => property.propertyId === selectedPropertyId), 1);
  };

  return (
    <>
      <div className='w-screen max-h-full top-0 pb-10 text-xs justify-center'>
        {properties?.length > 0 ? (
          <div id="fdm-properties-list" className="card text-center  ">
            <DataTable
              id="fdm-properties-data-table"
              value={properties}
              sortMode="multiple"
              tableStyle={{ minWidth: '50rem' }}
              size="normal"
              removableSort
              pt={{
                paginator: { pageButton: { className: 'rounded-full' } },
                header: { className: 'fdm-table-header ' },
                headerRow: { className: 'fdm-table-header-row ' },
                table: { className: 'fdm-table-table ' },
                footer: { className: 'fdm-table-footer ' },
                row: {
                  root: {
                    className: 'fdm-table-row ',
                  }
                }
              }}
            >
              <Column
                field="propertyDetails"
                key="propertyDetails"
                header={'Edit Decision'}
                bodyStyle={{ textAlign: 'center' }}
                body={(rowData) => {
                  return (
                    <a href={`/list-sorts/decision-making?id=${activeListSortId}&propertyId=${rowData.propertyId}`} className="text-sm font-semibold ml-2 text-gray-900" >
                      <span aria-hidden="true">←</span>
                    </a>);
                }}
              />
              <Column
                field="propertyDetailsDelete"
                key="propertyDetailsDelete"
                header={'Delete Decision'}
                bodyStyle={{ textAlign: 'center' }}
                body={(rowData) => {
                  return (
                    <button className="text-sm font-semibold ml-2 text-gray-900 hover:text-red-500"
                      onClick={() => {
                        handleDeleteClick(rowData.propertyId);
                      }}
                    >
                      <TrashIcon className="w-5 h-5" />
                    </button>);
                }}
              />
              <Column field="customListSortNumber" header="Sort" sortable style={{ width: '5%' }}></Column>

              <Column field="address" header="Address" sortable style={{ minWidth: 300 }}
                body={(data) =>
                  <div
                    className="hover:cursor-default hover:underline"
                    onClick={() => {
                      window.open(`/properties/${data.propertyId}`, '_blank');
                    }}
                  >{data.address}</div>
                }
              />

              <Column field="arvSelected" header="Estimated Value" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="approachName" header="Approach Name" sortable style={{ minWidth: '75px' }}></Column>
              <Column field="maxBid" header="Max Bid" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="repairEstimate" header="Repair Estimate" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="profitOnMaxBid" header="Profit on Max Bid" sortable style={{ minWidth: '100px' }}></Column>
              <Column field="roiPercent" header="ROI Percent" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="sqFt" header="Square Feet" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="yearBuilt" header="Year Built" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="bedrooms" header="Bedrooms" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="bathrooms" header="Bathrooms" sortable style={{ minWidth: '25%' }}></Column>
              <Column field="acreage" header="Acreage" sortable style={{ minWidth: '25%' }}></Column>
            </DataTable>
          </div>
        ) : (
          <div className='flex justify-center items-center h-full m-5 text-lg text-gray-500'>
          No properties in this list are ready for Final Decision
          </div>
        )}
      </div>
      {modalVisible && (
        <ConfirmDeleteLSPJModal
          propertyId={selectedPropertyId}
          onHide={() => setModalVisible(false)}
          activeListSortId={activeListSortId}
          handleDeleteConfirm={handleDeleteConfirm}
        />
      )}
    </>
  );
};

export default FinalDecisionMakingTable;
