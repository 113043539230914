// Utility functions to check if filters are active or default
import { FilterDescriptor } from './Filtering';

export const getActiveFilterIds = (filters: Record<string, FilterDescriptor>): Set<string> => {
  return new Set(Object.entries(filters).filter(([ _, filter ]) => isFilterActive(filter)).map(([ id ]) => id));
};

export const isFilterActive = (filter: FilterDescriptor): boolean => {
  return JSON.stringify(filter.values) !== JSON.stringify(filter.emptyValues);
};

export const areFiltersDefault = (filters: Record<string, FilterDescriptor>): boolean => {
  return Object.values(filters).every((filter) => {
    const isDefault = JSON.stringify(filter.values) === JSON.stringify(filter.defaultValues);
    const isEmpty = JSON.stringify(filter.values) === JSON.stringify(filter.emptyValues);

    // Only return true if the filter is either set to default or is empty
    return isDefault || isEmpty;
  });
};

export const getDefaultFilterIds = (filters: Record<string, FilterDescriptor>): Set<string> => {
  // This should get all the filters where the default value !== the empty value
  return new Set(
    Object.entries(filters)
      .filter(([ _, filter ]) => JSON.stringify(filter.defaultValues) !== JSON.stringify(filter.emptyValues))
      .map(([ id ]) => id)
  );
};
