import React, { type FC, useState } from 'react';

import LitePropertyList from './LitePropertyList';
import { ArrowIconSizeable } from '../icons/OurIcons';

interface DecisionMakingPropertySidebarProps {
  listSortId: string;
  activePropertyId: string;
  setActivePropertyId: (propertyId: string) => void;
  isSidebarOpen: boolean;
  setIsSidebarOpen: (isOpen: boolean) => void;
}

const DecisionMakingPropertySidebar: FC<DecisionMakingPropertySidebarProps> = ({
  listSortId,
  activePropertyId,
  setActivePropertyId,
  isSidebarOpen,
  setIsSidebarOpen,
}) => {
  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div id='DecisionMakingPropertySidebar' >
      {!isSidebarOpen ?
        (
          <div id='open-arrow-container'
            className='cursor-pointer left-0 absolute pl-2'
            onClick={toggleSidebar}>
            <div className='flex flex-row items-center justify-center'>
              <ArrowIconSizeable
                className="stroke-[0.5] hover:stroke-[.75] "
                color='black'
                width='16px'
                height='18px'
              />
            </div>
          </div>
        ) :
        (
          <div id='close-arrow-container'
            className='w-8 rotate-180'
            onClick={toggleSidebar}>
            <ArrowIconSizeable
              className="stroke-[0.5] hover:stroke-[.75]"
              color='black'
              width='16px'
              height='18px'
            />
          </div>
        )}
      <LitePropertyList
        listSortId={listSortId}
        activePropertyId={activePropertyId}
        setActivePropertyId={setActivePropertyId}
        isSidebarCollapsed={!isSidebarOpen}
      />
    </div>
  );
};

export default DecisionMakingPropertySidebar;
