import React, { FC, useEffect, useState } from 'react';
import ApproachViewer from './ApproachViewer';
import ApproachTable from './ApproachTable';
import { useGetApproaches } from '../../../hooks/api/Approaches/useGetApproaches';
import { LoadingSpinner } from '../../icons/OurIcons';
import { ComparisonConditions } from '../../configurations/approaches/approachTypes';
import { CalculatedApproach } from './calculatedApproach';

export type ApproachSelectProps = {
  propertyData: any,
  avmResult: any
  savedApproachId?: string;
  onApproachSelect: (CalculatedApproach) => void;
  fixedCosts: number;
  estimatedValue: number;
  isLoading: boolean,
  selectedApproach: CalculatedApproach;
  setSelectedApproach: (CalculatedApproach) => void;
};

const ApproachSelect: FC<ApproachSelectProps> = ({ 
  propertyData, 
  avmResult, 
  onApproachSelect, 
  savedApproachId, 
  fixedCosts, 
  estimatedValue, 
  isLoading,
  selectedApproach,
  setSelectedApproach
}) => {
  const [ selectedApproachIndex, setSelectedApproachIndex ] = useState<number>(null);
  const [ calculatedApproaches, setCalculatedApproaches ] = useState<CalculatedApproach[]>([]);

  const { data: approaches, refetch: refetchApproaches, isLoading: isLoadingApproaches, error: isErrorApproaches } = useGetApproaches();

  const clearApproachSelection = () => {
    setSelectedApproachIndex(null);
    setSelectedApproach(null);
  };

  useEffect(() => {
    if (propertyData) {
      clearApproachSelection();
    }
  }, [ propertyData ]);

  useEffect(() => {
    if (approaches && fixedCosts != null && estimatedValue != null && propertyData && avmResult ) {
      const comparisonConditions = new ComparisonConditions(propertyData, fixedCosts, avmResult);
      const filteredApproaches = comparisonConditions?.filterApplicableApproaches(approaches);
      const calculatedApproaches = filteredApproaches.map((approach) => new CalculatedApproach(approach, propertyData, fixedCosts, estimatedValue));
      setCalculatedApproaches(calculatedApproaches);
      if (savedApproachId) {
        const index = calculatedApproaches.findIndex((calculatedApproach) => calculatedApproach.approach.id === savedApproachId);
        if (index >= 0) {
          setSelectedApproachIndex(index);
        }
      }
    }
  }, [ approaches, propertyData, avmResult, estimatedValue, fixedCosts, savedApproachId ]);

  useEffect(() => {
    if (selectedApproachIndex !== null && selectedApproachIndex >= 0) {
      const selectedApproach = calculatedApproaches.at(selectedApproachIndex);
      setSelectedApproach(selectedApproach);
      onApproachSelect(selectedApproach);
    }
  }, [ selectedApproachIndex, calculatedApproaches ]);

  const ApproachContent = () => {
    if (isLoading || isLoadingApproaches) {
      return (
        <div className='flex items-center justify-center h-96'>
          <LoadingSpinner />
        </div>
      );
    }

    if (isErrorApproaches || !avmResult || !propertyData ) {
      return (
        <div className='flex items-center justify-center h-96'>
          <div className='text-center'>No Approach Data</div>
        </div>
      );
    }

    return (
      <div>
        <ApproachTable
          calculatedApproaches={calculatedApproaches}
          selectedApproachIndex={selectedApproachIndex}
          setSelectedApproachIndex={(approachIndex: number) => {setSelectedApproachIndex(approachIndex);}}
        />
        <ApproachViewer selectedApproach={selectedApproach}/>
        {selectedApproach && (
          <div
            className='flex justify-center mb-3'
          >
            <button
              onClick={clearApproachSelection}
              className='bg-indigo-600 text-white px-2 py-1 rounded-md'>
          Clear Selection
            </button>
          </div>
        )}
      </div>
    );
  };

  return (
    <div id='approach-select' className='w-contain content-center justify-content-center'>
      <div>
        <div className='flex justify-between m-3 items-center'>
          <h3 className='whitespace-nowrap text-extrabold text-lg text-indigo-500'>Approach Select</h3>
          <a
            href='/configurations'
            target='_blank'
            className="rounded whitespace-nowrap bg-indigo-600 px-2 py-2 text-xs font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
          >
            Manage Approaches
          </a>
        </div>
        <hr className='mt-3 mb-4 mx-3'/>
        <ApproachContent />
      </div>
    </div>
  );
};

export default ApproachSelect;
