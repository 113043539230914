// AppSettingsContext.tsx
import React, { createContext, useContext, ReactNode, useState, useEffect, useMemo } from 'react';
import { PaginationPerPageOptions } from '../components/propertySearch/TableSettingsMiniComponents';
import useSessionStorage from '../hooks/useSessionStorage';

interface AppSettingsContextProps {
    gridSize: 'small' | 'normal' | 'large';
    datatableGridSize: 'small' | 'normal' | 'large';
    setGridSize: (value: 'small' | 'normal' | 'large') => void;
    shouldShowGridLines: true | false;
    setShouldShowGridLines: (value: true | false) => void;
    shouldShowStripedRows: true | false;
    setShouldShowStripedRows: (value: true | false) => void;
    shouldFreezeHeaderRow: true | false;
    setShouldFreezeHeaderRow: (value: true | false) => void;
    paginationPerPageCount: PaginationPerPageOptions;
    setPaginationPerPageCount: (value: PaginationPerPageOptions) => void;
    isRowSelectionEnabled: true | false;
    toggleRowSelection: (val: true | false ) => void;
    isMapView: true | false;
    setIsMapView: React.Dispatch<React.SetStateAction<boolean>>;
    isFirstFewColumnsLocked: true | false;
    setFirstFewColumnsLocked: (value: true | false) => void;
  }

// NOTE: if you change an existing property here, you must either clean your session or force it to update.
//  otherwise you will only see the cached session storage which will not have your change
const AppSettingsContext = createContext<AppSettingsContextProps | undefined>(undefined);
export const AppSettingsProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [ gridSize, setGridSize ] = useState<'small' | 'normal' | 'large'>('small');
  const [ shouldShowGridLines, setShouldShowGridLines ] = useState<boolean>(true);
  const [ shouldShowStripedRows, setShouldShowStripedRows ] = useState<boolean>(true);
  const [ shouldFreezeHeaderRow, setShouldFreezeHeaderRow ] = useState<boolean>(true);
  const [ paginationPerPageCount, setPaginationPerPageCount ] = useState<PaginationPerPageOptions>(25);
  const [ isRowSelectionEnabled, toggleRowSelection ] = useState<boolean>(true);
  const [ isMapView, setIsMapView ] = useState<boolean>(false);
  const [ isFirstFewColumnsLocked, setFirstFewColumnsLocked ] = useState<boolean>(false);
  const { storedValue: tableSettings, setValue: setTableSettings } = useSessionStorage('table-settings-storage', {
    gridSize: 'small',
    shouldShowGridLines: true,
    shouldShowStripedRows: true,
    shouldFreezeHeaderRow: true,
    paginationPerPageCount: 25,
    isMapView: false,
    isFirstFewColumnsLocked: false,
  });

  // Initialize state by reading values from session storage
  useEffect(() => {
    // This needs to come from the db!!
    if (tableSettings) {
      setGridSize(tableSettings.gridSize as 'small' | 'normal' | 'large');
      setShouldShowGridLines(tableSettings.shouldShowGridLines);
      setShouldShowStripedRows(tableSettings.shouldShowStripedRows);
      setShouldFreezeHeaderRow(tableSettings.shouldFreezeHeaderRow);
      setPaginationPerPageCount(tableSettings.paginationPerPageCount as PaginationPerPageOptions);
      setIsMapView(tableSettings.isMapView);
      setFirstFewColumnsLocked(tableSettings.isFirstFewColumnsLocked);
    }
  }, []);

  // We are going to extend the "small" state to accomodate xsmall
  // the original ones are too large, so we are going to use small
  const datatableGridSize = useMemo(() => {
    switch (gridSize) {
    case 'large':
      return 'normal';
    case 'normal':
      return 'small';
    default:
      return 'small';
    }
  }, [ gridSize ]);

  // Update session storage whenever context state changes
  useEffect(() => {
    const updatedState = {
      gridSize,
      shouldShowGridLines,
      shouldShowStripedRows,
      shouldFreezeHeaderRow,
      paginationPerPageCount,
      isMapView,
      isFirstFewColumnsLocked,
    };
    setTableSettings(updatedState);
  }, [ gridSize, shouldShowGridLines, shouldShowStripedRows, shouldFreezeHeaderRow, paginationPerPageCount, isMapView, isFirstFewColumnsLocked ]);

  return (
    <AppSettingsContext.Provider value={{
      gridSize, setGridSize,
      datatableGridSize,
      shouldShowGridLines, setShouldShowGridLines,
      shouldShowStripedRows, setShouldShowStripedRows,
      shouldFreezeHeaderRow, setShouldFreezeHeaderRow,
      paginationPerPageCount, setPaginationPerPageCount,
      isRowSelectionEnabled, toggleRowSelection,
      isMapView, setIsMapView,
      isFirstFewColumnsLocked, setFirstFewColumnsLocked,
    }}>
      {children}
    </AppSettingsContext.Provider>
  );
};

export const useAppSettingsContext = () => {
  const context = useContext(AppSettingsContext);
  if (!context) {
    throw new Error('useAppSettingsContext must be used within a AppSettingsProvider');
  }
  return context;
};
