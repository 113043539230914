import { FC, useEffect, useState } from 'react';
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid';
import { Combobox } from '@headlessui/react';
import React from 'react';
import { useCreateListSortPropertyJoin } from '../../hooks/api/ListSortPropertyJoin/useCreateListSortPropertyJoin';
import { useGetListSorts } from '../../hooks/api/ListSort/useGetListSorts';
import useGetPropertyListSortPropertyJoins from '../../hooks/api/Property/useGetPropertyListSortPropertyJoins';
import { classNames } from '../../utility/utility';
import { useUpdateListSortPropertyJoin } from '../../hooks/api/ListSortPropertyJoin/useUpdateListSortPropertyJoin';

export type ModifyListSortMultiSelectProps = {
  propertyId: string
};

const ModifyListSortMultiSelect: FC<ModifyListSortMultiSelectProps> = ({ propertyId }) => {
  const defaultHelperText = 'Search or Select an Existing List';
  const [ displayText, setDisplayText ] = useState(defaultHelperText);
  const [ query, setQuery ] = useState('');
  const [ selectedPerson, setSelectedPerson ] = useState(null);

  const { data: createLSPJResponse, mutateAsync: createLSPJ, isError: createLSPJIsError, error: createLSPJError, isPending: createLSPJPending } = useCreateListSortPropertyJoin();
  const { data: updateLSPJResponse, mutateAsync: updateLSPJ, isError: updateLSPJIsError, error: updateLSPJError, isPending: updateLSPJPending } = useUpdateListSortPropertyJoin();
  const { data: listSorts, refetch: refetchGetListSorts, isLoading: listSortsLoading } = useGetListSorts();
  const { listSortPropertyJoins, refetch: refetchGetListSortPropertyJoins, errorListSortPropertyJoins, isLoading: loadingListSortPropertyJoins } = useGetPropertyListSortPropertyJoins(propertyId);

  const isLoading = listSortsLoading || loadingListSortPropertyJoins || createLSPJPending || updateLSPJPending;

  useEffect(() => { refetchGetListSortPropertyJoins(); }, [ createLSPJResponse, updateLSPJResponse ]);

  const filteredListSorts =
    query === ''
      ? listSorts
      : listSorts.filter((listSort) => {
        return listSort.title.toLowerCase().includes(query.toLowerCase());
      });

  const getPropertyListSorts = () => {
    const joins = listSortPropertyJoins?.list_sort_property_joins ?? [];
    const list_sorts = listSortPropertyJoins?.list_sorts_belonging_to_property ?? [];
    const joinsHash = {};
    joins.forEach((j) => {
      joinsHash[j.list_sort_id] = { 'join_created': true, 'wanted': j.wanted };
    });

    list_sorts.forEach((l) => {
      if (!(l.id in joinsHash)) {
        joinsHash[l.id] = { 'join_created': false, 'wanted': null };
      }
    });

    return joinsHash;
  };
  const propertyJoinsHash = getPropertyListSorts();

  const assignToListSort = (listSortId: string, wanted: boolean | null) => {
    const joinExists = propertyJoinsHash[listSortId]?.['join_created'] ?? false;
    if (!joinExists) {
      createLSPJ({ listSortId, propertyId: propertyId, status: wanted });
    } else {
      updateLSPJ({ listSortId, propertyId: propertyId, status: wanted });
    }
    setDisplayText(defaultHelperText);
  };

  const renderComboBox = () => {
    return (
      <div className="relative mt-2">
        <Combobox.Input
          className="w-full rounded-md border-0 bg-white py-1.5 pl-3 pr-10 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          onChange={(event) => setQuery(event.target.value)}
          displayValue={() => displayText}
          onClick={() => setDisplayText(query)}
        />
        <Combobox.Button className="absolute inset-y-0 right-0 flex items-center rounded-r-md px-2 focus:outline-none">
          <ChevronUpDownIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
        </Combobox.Button>

        {filteredListSorts.length > 0 && (
          <Combobox.Options id='ModifyListSortMultiSelect-options'
            className="absolute z-20 mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm"
          >
            {filteredListSorts.map((listSort) => (
              <Combobox.Option
                key={listSort.id}
                value={listSort}
                className='relative cursor-default select-none py-2 px-3 text-gray-900'
              >
                {({ active, selected }) => (
                  <>
                    <div className="flex justify-between items-center">
                      <div>
                        <p className="text-sm text-gray-700">{listSort.title}</p>
                      </div>
                      <div>
                        <span className="isolate inline-flex rounded-md shadow-sm">
                          <button
                            onClick={(_) => assignToListSort(listSort.id, true)}
                            type="button"
                            className={classNames(
                              'relative inline-flex items-center rounded-l-md px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300  focus:z-10',
                              propertyJoinsHash[listSort.id]?.['wanted'] === true ? 'bg-indigo-600 text-white hover:cursor-default' : 'bg-white text-gray-900 hover:bg-gray-100'
                            )}
                          >
                            Wanted
                          </button>
                          <button
                            onClick={(_) => assignToListSort(listSort.id, null)}
                            type="button"
                            className={classNames(
                              'relative -ml-px inline-flex items-center px-3 py-2 text-sm font-semibold ring-1 ring-inset ring-gray-300 focus:z-10',
                              propertyJoinsHash[listSort.id]?.['wanted'] === null ? 'bg-indigo-600 text-white hover:cursor-default' : 'bg-white text-gray-900 hover:bg-gray-100'
                            )}
                          >
                            Unassigned
                          </button>
                          <button
                            onClick={(_) => assignToListSort(listSort.id, false)}
                            type="button"
                            className={classNames(
                              'relative inline-flex items-center rounded-r-md  px-3 py-2 text-sm font-semibold text-gray-900 ring-1 ring-inset ring-gray-300 focus:z-10',
                              propertyJoinsHash[listSort.id]?.['wanted'] === false ? 'bg-indigo-600 text-white hover:cursor-default' : 'bg-white text-gray-900 hover:bg-gray-100'
                            )}
                          >
                            Unwanted
                          </button>
                        </span>
                      </div>
                    </div>
                  </>
                )}
              </Combobox.Option>
            ))}
          </Combobox.Options>
        )}
      </div>
    );
  };

  const renderLoadingBox = () => {
    return (
      <div className="flow-root">
        <div className="shadow rounded-md p-4 max-w-sm min-w-full">
          <div className="animate-pulse flex space-x-4">
            <div className="flex-1 space-y-6 py-1">
              <div className="h-2 bg-indigo-200 rounded"></div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderTitle = (): string => {
    var title = 'Assign to a list';

    const numberOfListsAssigned = Object.keys(propertyJoinsHash).length;

    if (loadingListSortPropertyJoins || numberOfListsAssigned == 0) {
      return title;
    }

    title += ` (currently assigned to ${numberOfListsAssigned} list`;

    if (numberOfListsAssigned > 1) {
      title += 's';
    }
    title += ')';

    return title;
  };

  return (
    <Combobox as="div" value={null} onChange={() => { setDisplayText(defaultHelperText); }}>
      <Combobox.Label className="block text-sm font-medium leading-6 text-gray-900 text-start">{renderTitle()}</Combobox.Label>
      { isLoading && renderLoadingBox() }
      { !isLoading && renderComboBox() }
    </Combobox>
  );
};

export default ModifyListSortMultiSelect;
