import { useQuery } from '@tanstack/react-query';
import { getFinalDecisionMakingProperties } from '../../../api/FinalDecisionMaking/getFinalDecisionMakingProperties';

export default function useGetFinalDecisionMakingProperties (listSortId: string) {
  const getQuery = useQuery({
    queryKey: [ 'getFinalDecisionMakingProperties', listSortId ],
    queryFn: () => getFinalDecisionMakingProperties(listSortId),
    enabled: !!listSortId
  });

  if (getQuery.isError) {
    console.error('There was an error in getFinalDecisionMakingProperties ', getQuery.error.message);
  }

  const finalDecisionMakingProperties = getQuery.data?.data?.filtered_uploads  ? Object.values(getQuery.data.data.filtered_uploads) : null;
  const propertyAuctionNumbers = getQuery.data?.data?.filtered_uploads_with_auction_numbers ? Object.values(getQuery.data.data.filtered_uploads_with_auction_numbers) : null;

  return {
    finalDecisionMakingProperties: finalDecisionMakingProperties,
    propertyAuctionNumbers: propertyAuctionNumbers,
    errorFinalDecisionMakingProperties: getQuery.error || null, // Access error
    isLoading: getQuery.isLoading,
    refetch: getQuery.refetch
  };
}
