import React, { type FC  } from 'react';
import useGetPropertyAgents from '../../../hooks/api/Property/useGetPropertyAgents';
import { formatToLocalDatetime } from '../../../utility/utility_functions';

type ListingAgentProps = {
  propertyDetails: any;
};

const ListingAgent: FC<ListingAgentProps> = ({ propertyDetails }) => {

  const { listingAgent } = useGetPropertyAgents(propertyDetails.id);

  const generateName = (): string => {
    let fullName = '';

    if (listingAgent?.first_name) {
      fullName = listingAgent?.first_name;
    }

    if (listingAgent?.last_name) {
      fullName = fullName ? `${fullName} ${listingAgent?.last_name}` : listingAgent?.last_name;
    }

    if (fullName.length > 0) {
      return fullName;
    }

    return 'Not provided';
  };

  const safelyDisplayString = (inputSting?: string): string => {
    if (inputSting) return inputSting;

    return 'Not provided';
  };

  if (!listingAgent) {
    return (
      <div className='text-center py-10 px-10 bg-blend-darken'>
        <p className='font-bold'>Listing agent details not available at this time</p>
      </div>
    );
  } else {
    return (
      <div className='mx-20 pb-2 px-10'>
        <div className='mt-6 grid grid-cols-1 lg:grid-cols-2 gap-4'>
          <div className='px-4 align-center shadow-md rounded-md'>
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Full name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{generateName()}</dd>
              </div>
            </dl>
            <dl className="grid grid-cols-1 sm:grid-cols-2">
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Phone Number</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.phone_number)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Office Phone Number</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.agent_office_phone_number)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Email address</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.email)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">MLS ID</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.third_party_id)}</dd>
              </div>
            </dl>
          </div>
          <div className='px-4 align-center shadow-md rounded-md'>
            <dl className="divide-y divide-gray-100">
              <div className="px-4 py-6 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Brokerage Name</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:col-span-2 sm:mt-0">{safelyDisplayString(listingAgent.office_name)}</dd>
              </div>
            </dl>
            <dl className="grid grid-cols-1 sm:grid-cols-2">
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Brokerage MLS ID</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.office_mls_id)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Brokerage Phone Number</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.office_phone_number)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Brokerage Email address</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{safelyDisplayString(listingAgent.office_email)}</dd>
              </div>
              <div className="border-t border-gray-100 px-4 py-6 sm:col-span-1 sm:px-0">
                <dt className="text-sm font-medium leading-6 text-gray-900">Last Updated</dt>
                <dd className="mt-1 text-sm leading-6 text-gray-700 sm:mt-2">{formatToLocalDatetime(listingAgent.listing_updated_at)}</dd>
              </div>
            </dl>
          </div>
        </div>
      </div>
    );
  }
};
export default ListingAgent;
